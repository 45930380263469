//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import clipboard from '@/util/clipBoard.js';
import Paging from '@/components/paging';
import config from '@/util/config';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      dialogVisible: !1,
      dialogVisible1: !1,
      allchecked: !1,
      batchActionId: '',
      freightList: [],
      id: [],
      freight_id: '',
      freight_price: '',
      freight_type: 0,
      classify_id: '',
      batchAction: [
        {
          name: '删除',
          val: 1,
        },
        {
          name: '上架',
          val: 2,
        },
        {
          name: '下架',
          val: 3,
        },
        {
          name: '修改运费',
          val: 4,
        },
        {
          name: '修改分类',
          val: 5,
        },
      ],
      goodsList: [],
      page: 1,
      rows: 10,
      total_number: 0,
      test_img: '',
    };
  },
  props: {
    content: {
      type: Object,
    },
    goodsClass: {
      type: Array,
    },
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.page = utils.page;
      this.rows = utils.rows;
      utils.is_record = !1;
    }
    this.getGoodsList();
    this.getfreightList();
  },
  methods: {
    //获取运费模板
    getfreightList() {
      this.$axios
        .post(this.$api.set.freightList, {
          rows: 10000,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.freightList = list;
          }
        });
    },
    //批量修改运费
    updateFreight() {
      let freight_type = this.freight_type;
      let data = {};
      let that = this;
      if (freight_type == 0) {
        data.freight_price = this.freight_price || 0;
      }
      if (freight_type == 1) {
        if (!this.freight_id) return this.$message.warning('请选择运费模板');
        data.freight_id = this.freight_id;
      }
      data.freight_type = this.freight_type;
      data.id = this.id;
      const updateRequest = () => {
        that.$axios.post(this.$api.goods.setFreight, data).then(res => {
          if (res.code == 0) {
            that.$message.success('修改成功');
            that.dialogVisible = !1;
          } else {
            that.$message.error(res.msg);
          }
        });
      };
      //判断是否有默认模板
      if (freight_type == 2) {
        this.$axios.post(this.$api.goods.isSelect).then(res => {
          if (res.code == 0) {
            if (res.result.is_exist) {
              updateRequest();
            } else {
              data.freight_type = 0;
              data.freight_price = 0;
              updateRequest();
            }
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        updateRequest();
      }
    },
    handleClassChange(val) {
      let arr = [];
      for (let i in val) {
        for (let y in val[i]) arr.push(val[i][y]);
      }
      this.classify_id = arr.join();
    },
    //批量修改分类
    updateGoodsClass() {
      this.$axios
        .post(this.$api.goods.setClassify, {
          id: this.id,
          classify_id: this.classify_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('修改成功');
            this.dialogVisible1 = !1;
            this.getGoodsList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
        this.getGoodsList();
      } else {
        this.page = val;
        this.getGoodsList();
      }
    },
    //修改商品信息
    editInfo(row, type) {
      if (type == 1) {
        this.$axios.post(this.$api.goods.editInfo, {
          id: row.id,
          rank: row.rank,
        });
      } else if (type == 2) {
        this.$axios.post(this.$api.goods.editInfo, {
          id: row.id,
          goods_price: row.goods_price,
        });
      } else if (type == 3) {
        this.$axios.post(this.$api.goods.editInfo, {
          id: row.id,
          goods_stock: row.goods_stock,
        });
      } else if (type == 4) {
        this.$axios.post(this.$api.goods.editInfo, {
          id: row.id,
          goods_prime_cost: row.goods_prime_cost,
        });
      }
    },
    //上架/下架
    setPutaway(row, index) {
      this.$confirm(row.is_putaway ? '请确认是否下架' : '请确认是否上架', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.goods.setPutaway, {
            id: row.id,
            is_putaway: row.is_putaway ? 0 : 1,
          })
          .then(res => {
            if (res.code == 0) {
              this.goodsList[index].is_putaway = row.is_putaway ? 0 : 1;
              this.$message({
                message: row.is_putaway ? '商品已上架' : '商品已下架',
                type: 'success',
              });
              this.$parent.$parent.getGoodsStatus();
              this.getGoodsList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    //处理批量上架/下架
    handlePutaway(type) {
      this.$axios
        .post(this.$api.goods.setPutaway, {
          id: this.id,
          is_putaway: type == 2 ? 1 : 0,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message({
              message: type == 2 ? '商品已上架' : '商品已下架',
              type: 'success',
            });
            this.allchecked = !1;
            this.$parent.$parent.getGoodsStatus();
            this.getGoodsList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //全选
    selectToatl(val) {
      if (val) {
        for (let i in this.goodsList) this.goodsList[i].checked = !0;
      } else {
        for (let i in this.goodsList) this.goodsList[i].checked = !1;
      }
    },
    //单选
    selectChange() {
      let checked = this.goodsList.filter(item => item.checked);
      if (!checked.length) this.allchecked = !1;
    },
    //批量操作
    handleBatchAction() {
      let goodsList = this.goodsList;
      this.id = [];
      for (let i in goodsList) {
        if (goodsList[i].checked) this.id.push(goodsList[i].id);
      }
      switch (this.batchActionId) {
        case 1:
          //批量删除商品
          if (!this.id.length) return this.$message.warning('请选择要删除的商品');
          this.$confirm('请确认是否批量删除所选商品', '批量删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios
              .post(this.$api.goods.delGoods, {
                id: this.id,
              })
              .then(res => {
                if (res.code == 0) {
                  this.getGoodsList();
                  this.$parent.$parent.getGoodsStatus();
                  this.$message.success('删除成功');
                } else {
                  this.$message.error(res.msg);
                }
              });
          });
          break;
        case 2:
          //批量上架
          if (!this.id.length) return this.$message.warning('请选择要上架的商品');
          this.handlePutaway(2);
          break;
        case 3:
          //批量下架
          if (!this.id.length) return this.$message.warning('请选择要下架的商品');
          this.handlePutaway(3);
          break;
        case 4:
          if (!this.id.length) return this.$message.warning('请选择要修改运费的商品');
          this.dialogVisible = !0;
          break;
        case 5:
          if (!this.id.length) return this.$message.warning('请选择要修改分类的商品');
          this.dialogVisible1 = !0;
          break;
        default:
          this.$message({
            message: '请选择批量操作类型',
            type: 'warning',
          });
      }
    },
    getGoodsList() {
      this.content.page = this.page;
      this.content.rows = this.rows;
      this.$axios.post(this.$api.goods.list, this.content).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].checked = !1;
            list[i].is_wxcode = !1;
            list[i].wxcode = '';
            list[i].is_classify_name = !0;
          }
          this.goodsList = list;
          this.$nextTick(() => {
            for (let i in list) {
              let classify_name = document.querySelector('.classify_name' + i);
              if (classify_name.scrollWidth > classify_name.offsetWidth) this.goodsList[i].is_classify_name = !1;
            }
          });
          this.total_number = res.result.total_number;
        } else {
          this.$message({
            message: res.msg,
            type: 'warning',
          });
        }
      });
    },
    copyGoodsId(e, id) {
      clipboard(e, id);
    },
    actionsType(row, type, i) {
      switch (type) {
        case 1:
          //编辑
          this.$router.push({
            path: '/goods/editGoods',
            query: {
              id: row.id,
              page: this.page,
              rows: this.rows,
            },
          });
          break;
        case 2:
          //删除商品
          this.$confirm('确认删除此商品？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$axios
              .post(this.$api.goods.delGoods, {
                id: row.id,
              })
              .then(res => {
                if (res.code == 0) {
                  let goodsList = [...this.goodsList];
                  goodsList.splice(i, 1);
                  this.total_number--;
                  this.$parent.$parent.getGoodsStatus();
                  this.goodsList = goodsList;
                  this.$message({
                    message: '删除成功',
                    type: 'success',
                  });
                } else {
                  this.$message.error(res.msg);
                }
              });
          });
          break;
        case 3:
          //复制商品
          this.$axios.post(this.$api.goods.copyGoods, { id: row.id }).then(res => {
            if (res.code == 0) {
              this.$parent.$parent.getGoodsStatus();
              this.getGoodsList();
              this.$message.success('商品复制成功');
            } else {
              this.$message.error(res.msg);
            }
          });
          break;
        case 4:
          if (row.wxcode) {
            setTimeout(() => {
              this.goodsList[i].is_wxcode = !0;
            }, 100);
          } else {
            this.$axios.post(this.$api.goods.wxCode, { id: row.id }).then(res => {
              if (res.code == 0) {
                this.goodsList[i].wxcode = config.baseurl + '/' + res.result;
                this.goodsList[i].is_wxcode = !0;
              } else {
                this.$message.error(res.msg);
              }
            });
          }
          break;
      }
    },
  },
};

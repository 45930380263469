//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GoodsList from './components/GoodsList';
import config from '@/util/config';
import _ from 'lodash';
export default {
  components: {
    GoodsList,
  },
  data() {
    return {
      templatePath: config.baseurl + '/' + 'Upload/import/商品导入模板.xlsx',
      goodsStatus: [],
      showImport: !1,
      content: {
        status: 0,
        goods_name: '',
        goods_coding: '',
        classify_id: '',
      },
      formData: '',
      filePath: '',
    };
  },
  computed: {
    goodsClass() {
      let goodsTypeList = this.$store.getters.goodsTypeList;
      goodsTypeList.unshift({
        value: 0,
        label: '全部',
      });
      return goodsTypeList;
    },
  },
  created() {
    this.$store.dispatch('goods/getTypeList');
    this.getGoodsStatus();
  },
  methods: {
    // 导出数据成excel
    exportEx() {
      this.$axios.post(this.$api.goods.export).then(res => {
        if (res.code == 0) {
          let path = config.baseurl + '/' + res.result;
          let a = document.createElement('a');
          a.href = path;
          a.id = 'download';
          document.body.appendChild(a);
          a.click();
          let aDom = document.getElementById('download');
          document.body.removeChild(aDom);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getFile() {
      let files = this.$refs.inputer.files[0];
      let formData = new FormData();
      formData.append('file', files);
      this.filePath = files.name;
      this.formData = formData;
    },
    comfirmImport() {
      if (!this.formData) return this.$message.warning('请先上传文件');
      this.$axios.uploadFile(this.$api.goods.import, this.formData).then(res => {
        if (res.code == 0) {
          this.showImport = !1;
          this.$message.success('商品导入成功');
          this.$refs.goodsList.getGoodsList();
          this.getGoodsStatus();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      this.$refs.goodsList.page = 1;
      this.$refs.goodsList.rows = 10;
      this.$refs.goodsList.allchecked = !1;
      this.$refs.goodsList.getGoodsList();
    },
    typeChange(val) {
      val.length - 1 ? (this.content.classify_id = val[1]) : (this.content.classify_id = val[0]);
    },
    getGoodsStatus() {
      this.$axios.post(this.$api.goods.goodsStatus, {
        goods_name:this.content.goods_name,
        goods_coding:this.content.goods_coding,
        classify_id:this.content.classify_id
      }).then(res => {
        if (res.code == 0) {
          this.goodsStatus = res.result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    searchGoods() {
      this.getGoodsStatus();
      this.$refs.goodsList.getGoodsList();
    },
    searchClear() {
      this.content.goods_name = '';
      this.content.goods_coding = '';
      this.content.classify_id = '';
      this.getGoodsStatus();
      this.$refs.goodsList.getGoodsList();
    },
  },
};
